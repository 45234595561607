//@ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Divider,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18n from "i18next"
import { logo, MyClasses, UpdateAvailibility, CreateNewClass, ColorMyClasses, ColorUpdateAvailibility, ColorCreateNewClass, Profile, Payment, Logout, smallLogo, drawer, standardProfileIcon } from './assests';
type MyProps = { 
    params: string; 
    navigation: any; 
    children?: any; 
    screenName: string; 
    profileUrl?: any; 
    handleSettingProfile?: (value: boolean, response: any) => void;
    t?: any;
    i18n?: any;
};
type MyState = { changeColorIcon: string; params: string; openSettingModal: boolean, isSmallScreen: boolean };


class CustomTeacherNavigationLayout extends Component<MyProps, MyState>{

    static propTypes = {
        navigation: PropTypes.any,
        children: PropTypes.any,
        screenName: PropTypes.string,
        handleSettingProfile: PropTypes.func,
        profileUrl: PropTypes.any,
        t: PropTypes.any,
        i18n: PropTypes.any
    };

    constructor(props: any) {
        super(props);
        this.state = {
            changeColorIcon: '',
            params: this.props.params,
            openSettingModal: false,
            isSmallScreen: window.innerWidth <= 1024,
        };
    }

    componentDidMount(): void {
        this.detectSmallScreen()
        this.setState({ changeColorIcon: this.props.screenName })
        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        i18n.changeLanguage(appLanguage)
    }

    handleValueClick(path: any) {
        this.setState({ changeColorIcon: path })
        this.props.navigation.navigate(path);
    }
    handleValueChange() {
        this.setState({ openSettingModal: !this.state.openSettingModal })
        // this.props.navigation.navigate('CustomisableUserProfiles')
    }

    reditecToPage(value: string) {
        if (value === 'logout') {
            sessionStorage.removeItem("loggedInTime");
            localStorage.clear()
        }
        this.setState({ openSettingModal: !this.state.openSettingModal })
    }

    checkIsURLExits = () => {
        if (this.state.isSmallScreen) return drawer
        if (this.props.profileUrl) {
            return this.props.profileUrl
        } else {
            const user = (typeof localStorage !== "undefined" && localStorage.getItem('user')) || '';
            const parsedUser = user ? JSON.parse(user) : null;
            if (parsedUser != null) {
                return parsedUser.attributes.image_link
            }
        }
    }

    redirectToDashboard = () => {
        this.props.navigation.navigate('MyClasses');
    }

    renderMenu = () => {
        const {t} = this.props
        return (
            <>
                <Box className='navigationBar' onClick={() => this.handleValueClick('MyClasses')}>
                    <img src={this.state.changeColorIcon == 'MyClasses' ? ColorMyClasses : MyClasses} className='navIcon' />
                    <Typography className='menuFont' style={{ color: this.state.changeColorIcon == 'MyClasses' ? '#d23d65' : '#898989' }} >{t("My Classes")}</Typography>
                </Box>
                <Box className='navigationBar' onClick={() => this.handleValueClick('UpdateAvailability')}>
                    <img src={this.state.changeColorIcon == 'UpdateAvailability' ? ColorUpdateAvailibility : UpdateAvailibility} className='navIcon' />
                    <Typography className='menuFont' style={{ color: this.state.changeColorIcon == 'UpdateAvailability' ? '#d23d65' : '#898989' }}>{t("Update Availability")}</Typography>
                </Box>
                <Box className='navigationBar' onClick={() => this.handleValueClick('CreateClass')}>
                    <img src={this.state.changeColorIcon == 'CreateClass' ? ColorCreateNewClass : CreateNewClass} className='navIcon' />
                    <Typography className='menuFont' style={{ color: this.state.changeColorIcon == 'CreateClass' ? '#d23d65' : '#898989' }} >{t("Create New Classes")}</Typography>
                </Box>
            </>
        )
    }

    detectSmallScreen = () => {
        window.addEventListener('resize', () => {
            const smallScreen = window.innerWidth <= 1024
            this.setState({ isSmallScreen: smallScreen })
        });
    }

    render() {
        const { isSmallScreen } = this.state;
        const { t } = this.props
        return (
            <NavigationContainer>
                <div className='Container'>
                    <Box className='mainWrapper'>
                        <img onClick={this.redirectToDashboard} src={isSmallScreen ? smallLogo : logo} className='navigationLogo' />
                        {this.props.screenName !== "EmailVerification" && <>
                            <Box className='navigation'>
                                {this.renderMenu()}
                            </Box>
                            <img src={this.checkIsURLExits()??standardProfileIcon} className='profile' onClick={() => this.handleValueChange()} /></>}
                        {this.state.openSettingModal && <Box className='settingModal'>
                            <div className='teacherNavigationMenu'>
                                {this.renderMenu()}
                                <Divider />
                            </div>
                            <div className='settingModalItem' onClick={() => this.reditecToPage('profile')}>
                                <img className='settingModalImgIcon' src={Profile} />
                                <Link to='EditTeacherProfile' className='settingModalText'>{t("Profile & Settings")}</Link>
                            </div>
                            <div className='settingModalItem' onClick={() => this.reditecToPage('payment')}>
                                <img className='settingModalImgIcon' src={Payment} />
                                <Link to='TeacherPaymentDetails' className='settingModalText'>{t("Payment Details")}</Link>
                            </div>
                            <div className='settingModalItem' onClick={() => this.reditecToPage('logout')}>
                                <img className='settingModalImgIcon' src={Logout} />
                                <Link to='TeacherLogin' className='settingModalText'>{t("Logout")}</Link>
                            </div>
                        </Box>}
                    </Box>
                    <hr className='horizontalLine' />

                </div>
                {this.props.children}
            </NavigationContainer>
        )
    }
}

const NavigationContainer = styled("div")({
    "& .Container": {
        margin: " 0px 50px",
        "@media(max-width:1024px)": {
            margin: '6px 0'
        }
    },
    "& .mainWrapper": {
        display: "flex",
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'OpenSansRoman-SemiBold',
        flexDirection: "row",
        alignItems: "center",
        background: "#fff",
        justifyContent: 'space-between',
        height: '90px',
        position: 'relative',
        margin: '10px 0px',

        "@media(max-width:1024px)": {
            height: '47px',
            padding: '0 27px',
            margin: '0'
        }
    },
    "& .menuFont": {
        fontSize: '22px',
        fontFamily: "'Open sans', semibold",
        fontWeight: '600',
        "@media (max-width:1200px)":{
            fontSize:'14x !important',
        },

        "@media (max-width:1024px)":{
            fontSize:'20px !important',
        },
    },
    "& .horizontalLine": {
        borderColor: '#D1D1D1',
        margin: 0
    },
    "& .navigationLogo": {
        height: '80px',
        cursor: 'pointer',
        
        "@media (max-width:1200px)":{
            height: '65px'
        },
        "@media (max-width:1024px)": {
            width: '48px',
            height: '48px'
        }
    },
    "& .navigationBar": {
        flexDirection: "column",
        textAlign: 'center',
        cursor: 'pointer',

        "@media (max-width:1024px)": {
            display: 'flex',
            columnGap: '20px',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '0px'
        }
    },
    "& .navigation": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        columnGap: '60px',

        "@media(max-width:1300px)": {
            columnGap: '40px'
        },

        "@media(max-width:1024px)": {
            display: 'none'
        },
    },
    "& .profile": {
        borderRadius: '50%',
        borderWidth: '1px',
        backgroundColor: 'lightgray',
        width: '70px',
        height: '70px',
        objectFit:'cover',
        cursor: 'pointer',
        "@media (max-width:1200px)":{
            width: '60px',
            height: '60px',
        },

        "@media (max-width:1024px)": {
            width: '20px',
            height: '12px',
            borderRadius: '0'
        },
    },
    "& .navIcon": {
        marginBottom: '10px',
    },
    "& .settingModal": {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        padding: "30px",
        top: '106px',
        right: '0px',
        boxShadow: '0px 3px 9px -3px #7a7a7a',
        rowGap: '12px',
        borderRadius: '10px',
        zIndex: 11,
        background: 'white',

        "@media (max-width:1024px)": {
            top: '50px',
            right: '25px',
            left: '25px'
        },
    },

    "& .teacherNavigationMenu": {
        display: 'none',
        "@media (max-width:1024px)":{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px'
        },
    },

    "& .settingModalItem": {
        display: 'flex',
        columnGap: '20px',
        alignItems: 'center',
    },
    "& .settingModalImgIcon": {
        width: '20px',
        height: '20px'
    },
    "& .settingModalText": {
        color: "#3a3a3a",
        margin: "5px 0px",
        fontWeight: 500,
        fontFamily: "'Open sans', regular",
        fontSize: "20px",
        textDecoration: 'none',
        cursor: 'pointer'
    }
})
export default withTranslation()(CustomTeacherNavigationLayout);
