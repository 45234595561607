// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import "react-datepicker/dist/react-datepicker.css";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles.web";
import CustomisableStudentProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableStudentProfiles.web";
import ProfileView from "../../blocks/CustomisableUserProfiles/src/ProfileView.web";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import CfGroupClassesMinimumAndMaximum from "../../blocks/CfGroupClassesMinimumAndMaximum/src/CfGroupClassesMinimumAndMaximum";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Payment from "../../blocks/stripepayments/src/Payment.web.tsx";
import Payments from "../../blocks/Payments/src/Payments";
// import PaymentDetails from "../../blocks/Payments/src/PaymentDetails";
import TeacherPaymentDetails from '../../blocks/Payments/src/TeacherPaymentDetails.web'
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import TeacherForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TeacherNewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import PaypalIntegration from "../../blocks/PaypalIntegration/src/PaypalIntegration";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import MyClasses from "../../blocks/appointmentmanagement/src/TeacherDashboard.web";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import PremiumPlanComparison from "../../blocks/PremiumPlanComparison/src/PremiumPlanComparison";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import TeacherLoginBlock from "../../blocks/email-account-login/src/TeacherLoginBlock.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import HomePage from "../../blocks/landingpage/src/LandingPage.web";
import SavedAvailability from "../../blocks/appointmentmanagement/src/SavedAvailability.web";
import MembershipBlock from "../../blocks/customisableusersubscriptions/src/MembershipBlock.web";
import MembershipUpdatedSuccessful from "../../blocks/customisableusersubscriptions/src/MembershipUpdatedSuccessful.web";
import ManagePaymentDetailsBlock from "../../blocks/customisableusersubscriptions/src/ManagePaymentDetailsBlock.web";
import UpdateMembershipPlan from "../../blocks/customisableusersubscriptions/src/UpdateMembershipPlan.web";
import CancelSubscriptionPlan from "../../blocks/customisableusersubscriptions/src/CancelSubscriptionBlock.web";
import BuyLingosBlock from "../../blocks/customisableusersubscriptions/src/BuyLingosBlock.web";
import DonateLingosBlock from "../../blocks/customisableusersubscriptions/src/DonateLingosBlock.web";
import ReferralWelletBlock from '../../blocks/customisableusersubscriptions/src/ReferralWallet.web';
import LingosDonated from "../../blocks/customisableusersubscriptions/src/LingosDonated.web";
import LingosPurchased from "../../blocks/customisableusersubscriptions/src/LingosPurchased.web";
import Wallet from "../../blocks/customisableusersubscriptions/src/WalletBlock.web";
import InvoiceBlock from "../../blocks/customisableusersubscriptions/src/Invoice.web";
import MembershipInvoice from "../../blocks/customisableusersubscriptions/src/MembershipInvoice.web"
import TimeZoneDetection from "../../blocks/timezonedetection/src/TimeZoneDetection";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
// import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import WordpressIntegration from "../../blocks/WordpressIntegration/src/WordpressIntegration";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import StoreCredits from "../../blocks/StoreCredits/src/StoreCredits";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest.web";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import EmailVerified from "../../components/src/EmailVerified.web";
import TeacherSignupBlock from "../../blocks/email-account-registration/src/TeacherSignupBlock.web";
import EmailVerification from "../../blocks/email-account-registration/src/EmailVerification.web";
import NotificationSetting from "../../blocks/Settings5/src/NotificationSetting.web";
import ChangePasswordSetting from "../../blocks/Settings5/src/ChangePasswordSetting.web";
import TimeZoneSetting from "../../blocks/Settings5/src/TimeZoneSetting.web";
import LanguageSetting from "../../blocks/Settings5/src/LanguageSetting.web";
import WalletSetting from "../../blocks/Settings5/src/WalletSetting.web";
import InviteFriends from "../../blocks/Settings5/src/InviteFriends.web";
// import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
// import Settings2 from "../../blocks/settings2/src/Settings2";
// import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Inapppurchasing from "../../blocks/inapppurchasing/src/Inapppurchasing";
import UpdateAvailability from "../../blocks/catalogue/src/UpdateAvailability.web";
import BookClasses from "../../blocks/catalogue/src/BookClasses.web";
import ConfirmedClassBooking from "../../blocks/catalogue/src/ConfirmedClassBooking.web";
import CreateClassConfirmationPage from "../../blocks/catalogue/src/CreateClassConfirmationPage.web";
import Details from "../../blocks/catalogue/src/Details.web";
import CreateClass from "../../blocks/catalogue/src/CreateClass.web";
import StudentDashboard from "../../blocks/appointmentmanagement/src/StudentDashboard.web";
import { ToasterContext, Toaster } from "../../components/src/ToasterConfig";
import { withRouter } from 'react-router';
import UpdatePaymentMethod from '../../blocks/customisableusersubscriptions/src/UpdatePaymentMethod.web';
import { getBrowserStorageData } from "../../components/src/utils";
import 'react-multi-carousel/lib/styles.css';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
const routeMap = {
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Inapppurchasing:{
 component:Inapppurchasing,
path:"/Inapppurchasing"},

// Customisableuserprofiles2:{
//  component:Customisableuserprofiles2,
// path:"/Customisableuserprofiles2"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
// Settings2:{
//  component:Settings2,
// path:"/Settings2"},
// Rolesandpermissions:{
//  component:Rolesandpermissions,
// path:"/Rolesandpermissions"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},

TimeZoneDetection:{
 component:TimeZoneDetection,
path:"/TimeZoneDetection"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
Payment: {
  component: Payment,
  path: "/Payment"
},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
// StripeIntegration:{
//  component:StripeIntegration,
// path:"/StripeIntegration"},
WordpressIntegration:{
 component:WordpressIntegration,
path:"/WordpressIntegration"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
StoreCredits:{
 component:StoreCredits,
path:"/StoreCredits"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},

EditTeacherProfile:{
 component:CustomisableUserProfiles,
path:"/EditTeacherProfile"},
CreateTeacherProfile:{
  component: CustomisableUserProfiles,
  path: '/CreateTeacherProfile'
},
// EditStudentProfile: {
//   component: CustomisableStudentProfiles,
//   path: '/EditStudentProfile',
//   exact: true
// },
// ProfileView: {
//   component: ProfileView,
//   path: '/Profile',
//   exact: true
// },
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
CfGroupClassesMinimumAndMaximum:{
 component:CfGroupClassesMinimumAndMaximum,
path:"/CfGroupClassesMinimumAndMaximum"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Payments:{
 component:Payments,
path:"/Payments"},
TeacherPaymentDetails:{
 component:TeacherPaymentDetails,
path:"/TeacherPaymentDetails"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
TeacherForgotPassword:{
  component: TeacherForgotPassword,
 path:"/TeacherForgotPassword"},
 StudentForgotPassword:{
  component: TeacherForgotPassword,
 path:"/StudentForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TeacherNewPassword:{
  component:TeacherNewPassword,
 path:"/TeacherNewPassword"},
 TeacherEmailVerified: {
  component: EmailVerified,
  path:"/TeacherEmailVerified"
 },
 StudentEmailVerified: {
  component: EmailVerified,
  path:"/StudentEmailVerified"
 },
 StudentNewPassword:{
  component:TeacherNewPassword,
 path:"/StudentNewPassword"},
PaypalIntegration:{
 component:PaypalIntegration,
path:"/PaypalIntegration"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
MyClasses:{
  component: MyClasses,
  path: '/MyClasses'},
SavedAvailability: {
  component: SavedAvailability,
  path: "/SavedAvailability",
},
Settings5:{
 component:Settings5,
path:"/Settings5"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
PremiumPlanComparison:{
 component:PremiumPlanComparison,
path:"/PremiumPlanComparison"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
TeacherLogin: {
  component:TeacherLoginBlock,
  path:"/TeacherLogin"
},
StudentLogin: {
  component:TeacherLoginBlock,
  path:"/StudentLogin"
},
TeacherSignup: {
  component:TeacherSignupBlock,
  path:"/TeacherSignup"
},
EmailVerification: {
  component:EmailVerification,
  path:"/EmailVerification"
},
StudentSignup: {
  component:TeacherSignupBlock,
  path:"/StudentSignup"
},
HomePage:{
  component:HomePage,
  path:"/HomePage"
},
Membership: {
  component: MembershipBlock,
  path: '/Membership'
},
MembershipUpdatedSuccessful: {
  component: MembershipUpdatedSuccessful,
  path: '/MembershipUpdatedSuccessfully'
},
ManagePaymentDetail: {
  component: ManagePaymentDetailsBlock,
  path: '/ManagePaymentDetail'
},
MembershipPlan: {
  component: UpdateMembershipPlan,
  path: '/MembershipPlan'
},
UpdatePaymentMethod:{
  component:UpdatePaymentMethod,
  path:'/UpdatePayment'
},
CancelSubscription: {
  component: CancelSubscriptionPlan,
  path: '/CancelSubscription'
},
Invoice: {
  component: InvoiceBlock,
  path: '/Invoice'
},
BuyLingosBlock: {
  component: BuyLingosBlock,
  path: '/BuyLingos'
},
DonateLingosBlock: {
  component: DonateLingosBlock,
  path: '/DonateLingos'
},
ReferralWelletBlock: {
  component: ReferralWelletBlock,
  path: '/ReferralWellet'
},
LingosDonated: {
  component: LingosDonated,
  path: '/LingosDonated'
},
LingosPurchased: {
  component: LingosPurchased,
  path: '/LingosPurchased'
},
Wallet: {
  component: Wallet,
  path: '/Wallet'
},
MembershipInvoice: {
  component: MembershipInvoice,
  path: '/MembershipInvoice'
},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
NotificationSetting: {
  component:NotificationSetting,
  path:"/NotificationSetting",
  exact: true
},
ChangePasswordSetting: {
  component: ChangePasswordSetting,
  path:"/ChangePasswordSetting",
  exact: true
},
TimeZoneSetting: {
  component: TimeZoneSetting,
  path: "/TimeZoneSetting",
  exact: true
},
UpdateAvailability: {
  component: UpdateAvailability,
  path: "/UpdateAvailability",
  exact: true
},
BookClasses: {
  component: BookClasses,
  path: "/BookClasses",
  exact: true
},
ConfirmedClassBooking: {
  component: ConfirmedClassBooking,
  path: "/ConfirmedClassBooking",
  exact: true
},
EditStudentProfile: {
  component: CustomisableStudentProfiles,
  path: '/EditStudentProfile',
  exact: true
},
CreateClassConfirmationPage: {
  component: CreateClassConfirmationPage,
  path: "/CreateClassConfirmationPage",
  exact: true
},
Details: {
  component: Details,
  path: "/view-details/:type/:classId",
  exact: true
},
CreateClass: {
  component: CreateClass,
  path: "/CreateClass",
  exact: true
},


ProfileView: {
  component: ProfileView,
  path: '/Profile',
  exact: true
},

LanguageSetting: {
  component: LanguageSetting,
  path: "/LanguageSetting",
  exact: true
},
WalletSetting:{
component:WalletSetting,
path:"/WalletSetting",
exact:true
},

InviteFriends: {
  component: InviteFriends,
  path: "/InviteFriends",
  exact: true
},

StudentDashboard: {
  component: StudentDashboard,
  path:"/StudentDashboard",
  exact: true
},

  Home: {
component:NavigationMenu,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: true
  // }

};

const privateRoutes = [
  "/studentemailverified",
  "/teacheremailverified",
  "/sudentnewpassword",
  "/teachernewpassword",
  "/studentnewpassword",
  "/teachersignup",
  "/teacherlogin",
  "/studentsignup",
  "/studentlogin",
]

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  state = {
    openToaster: false,
    toasterText: '',
    toasterType: 'info', // default set to warning type
    rememberMe: false,
  }
   
  toggleToaster = (flag) => {
    this.setState({ openToaster: flag })
    if (!flag) {
      this.handleToaster({ toasterText: '' }) // set to default
    }
  }

  handleToaster = (data) => {
    this.setState({ ...data })
  }


  handleWindowClose = async (e) => {
    let session = await getBrowserStorageData('isSession', 'session');
    const isRememberMe = await getBrowserStorageData('remember', 'local');
    const path = window.location.href.toLocaleLowerCase();
    const getToken = JSON.parse(localStorage.getItem('authToken'));
    const emailRedirectionUrl = window.location;

    const urlParams = new URLSearchParams(emailRedirectionUrl.search);
    const token = urlParams.get('token');
    const referralCode = urlParams.get('referralCode');
    //Customize code start here

    const teacherAndStudentRoutes = [
      "/updateavailability", 
      "/createclass",
      "/BookClasses",

    ]

      // Check if the URL contains 'updateavailability' or 'createclass' and if token exists
    if ((path.includes('/updateavailability') || path.includes('/createclass')) && token) {
      if (!getToken) {  // If user is not authenticated
        this.props.history.push('/teacherlogin');
        // console.log("Teacher Login page..")
      }
    }
    if ((path.includes('/bookclasses') || path.includes('/assessmenttest')) && token) {
      if (!getToken) {  // If user is not authenticated
        this.props.history.push('/studentlogin');
        // console.log("Teacher Login page..")
      }
    }
    
    if (isRememberMe == null && session == null) {
      if (!privateRoutes.includes(path)) {
        if ((!token && !getToken) && !referralCode) {
          this.props.history.push('/LandingPage');
          console.log("History Landing Page")
          localStorage.clear();
        }
      }
    }

    sessionStorage.setItem('isSession', 1);

    let loggedInTime = sessionStorage.getItem('loggedInTime');
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    let currentTime = new Date().getTime();
    let timeDiff = currentTime - (loggedInTime ? parseInt(loggedInTime) : 0);
    
    // Assuming `getToken` is a value that returns whether a valid token exists
    if (loggedInTime && timeDiff >= 86400000 && getToken) {
      // Clear localStorage
      localStorage.clear();
    
      // Handle redirection based on user type
      if (user && user.type === "teacher") {
        this.props.history.push('/teacherLogin');
      } else if (user && user.type === "student") {
        this.props.history.push('/studentLogin');
      } 
    
      // Remove the logged-in time from sessionStorage
      sessionStorage.removeItem("loggedInTime");
    }
    //Customize code ends here
  }

  componentDidMount() {
    window.addEventListener('load', this.handleWindowClose)
  }

  componentWillUnmount(){
    window.removeEventListener('load');
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    const contextValue = {
      toggleToaster: this.toggleToaster,
      handleToaster: this.handleToaster
    }

    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {/* <TopNav /> */}
        <ToasterContext.Provider value={contextValue}>
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
        </ToasterContext.Provider>
        <Toaster
          openToaster={this.state.openToaster}
          toggleToaster={this.toggleToaster}
          toasterText={this.state.toasterText}
          toasterType={this.state.toasterType}
        />
      </View>
    );
  }
}

export default withRouter(App);